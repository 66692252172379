import React from "react"
import styled from "styled-components"
import { Accordion } from "../components/Accordion"
import SEO from "../components/SEO/seo"
import { TrialTemplate2 } from "../components/commonFeatureComp"

import headerBackground from "../../images/header-background.png"
import howItWorksMobile from "../../images/how-it-works-mobile.png"
import howItWorksWeb from "../../images/how-it-works-web.png"

import noMaxLimit from "../../images/no-maximum-limit.png"
import customDashboard from "../../images/custom-dashboard.png"
import handyResources from "../../images/handy-resources.png"

const HeadingSection = styled.header`
  display: grid;
  grid-template-columns: minmax(auto, 650px);
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 10px;
  background-image: url(${headerBackground});
  padding: 3em 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
const TitleH1 = styled.h1`
  font-size: 40px;
  color: #33475b;
  text-align: center;
  margin-bottom: 0.3em;
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleText = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-top: 0%;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const JoinButton = styled.a`
  padding: 16px 30px;
  border-radius: 6px;
  background-color: #33475b;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-top: 20px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const TitleH2 = styled.h2`
  font-size: 26px;
  text-align: center;
  margin: 1em 0 0.83em;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const WebImage = styled.img`
  display: block;
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileImage = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const ContentContainer = styled.section`
  display: grid;
  grid-template-columns: minmax(auto, 725px);
  gap: 20px;
  justify-content: center;
  padding: 30px;
`
const InsideSection = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 585px);
  gap: 10px;
  justify-content: center;
`
const IconImage = styled.img`
  width: 60px;
  height: 60px;
  margin-top: 20px;
`
const TitleH3 = styled.h3`
  margin-bottom: 0.6em;
`
const ContentText = styled.p`
  font-size: 16px;
  margin-top: 0;
  font-size: 500;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const ColorSpan = styled.span`
  color: #fff;
`

export default ({ location }) => (
  <>
    <SEO
      title="Groupboss Affiliate Program with a Lucrative Commission Rate"
      description="Join Groupboss affiliate program and get 50% one-time commission for every user you refer. There is no limit and you can generate unlimited income."
      pathname={location.pathname}
      faq={[
        {
          ques: `I have signed up for the Groupboss affiliate program. How can I get the link to promote Groupboss?`,
          ans: `After signing up to the program, your application will be reviewed. If you are accepted, you will receive a welcome email, including your unique link to share and information on how to visit your dashboard.`,
        },
        {
          ques: `How will my commissions be calculated?`,
          ans: `As written above, you will get a 50% commission for each user you refer. Remember, this is not a recurring commission. If a user purchases a yearly plan, you’ll get a 50% commission for the first year’s subscription payment. You won’t be eligible for the second or next onward subscription renewals.`,
        },

        {
          ques: `How are my referrals being tracked?`,
          ans: `We use cookies to track your referral traffic. The cookies last for 90 days after someone clicks on your link. You’ll get 90 days of restarts if they click again.`,
        },
        {
          ques: `Have more questions?`,
          ans: `Please send a mail to “contact@groupboss.io” if you have more queries.`,
        }
      ]}
    />
    <article>
      <HeadingSection>
        <TitleH1>
          Become a <ColorSpan>Groupboss Affiliate</ColorSpan> and Start Earning
        </TitleH1>
        <TitleText>
          Join the Groupboss affiliate program and get a{" "}
          50% commission for each user you refer. There
          is no limit. This is one of the highest rates in the industry.
        </TitleText>
        <JoinButton href="https://affiliates.groupboss.io/login">Join Groupboss Affiliate Program</JoinButton>
      </HeadingSection>
      <ContentContainer>
        <TitleH2>How It Works</TitleH2>
        <WebImage
          src={howItWorksWeb}
          alt="groupboss affiliate workflow"
          style={{ margin: `auto 0` }}
          width="100%"
        />
        <MobileImage
          src={howItWorksMobile}
          alt="groupboss affiliate workflow"
          width="100%"
        />
        <TitleH2>What Makes Groupboss Affiliate Program Awesome?</TitleH2>
        <TitleText>
          Making our partnership official is a huge deal, so in return, we’ll
          help with everything you need. You’ll get:
        </TitleText>
        <InsideSection>
          <IconImage src={noMaxLimit} alt="no max limit" />
          <TitleH3>No Maximum Limit on Referring or Cashout</TitleH3>
          <ContentText>
            There is no limit on the number of people you can refer to
            Groupboss. There is no maximum limit for cash out.
          </ContentText>

          <IconImage src={customDashboard} alt="custom dashboard" />
          <TitleH3>A Custom Dashboard to Track Progress</TitleH3>
          <ContentText>
            Check how your referral strategies are working with a dashboard
            tracking every click and reward. It’s one browser tab we bet you
            won’t be able to resist refreshing.
          </ContentText>

          <IconImage src={handyResources} alt="handy resources" />
          <TitleH3>Handy Resources and Training Materials</TitleH3>
          <ContentText>
            Need some quality enablement assets to put your best foot forward?
            You got it! You can start referring right away with our training and
            promotional materials.
          </ContentText>
        </InsideSection>
      </ContentContainer>

      <TrialTemplate2 />

      <ContentContainer>
        <TitleH2>Frequently asked questions</TitleH2>
        <Accordion
          title="I have signed up for the Groupboss affiliate program. How can I get the link to promote Groupboss?"
          content="After signing up to the program, your application will be reviewed. If you are accepted, you will receive a welcome email, including your unique link to share and information on how to visit your dashboard."
        />
        <Accordion
          title="How will my commissions be calculated?"
          content="As written above, you will get a 50% commission for each user you refer. Remember, this is not a recurring commission. If a user purchases a yearly plan, you’ll get a 50% commission for the first year’s subscription payment. You won’t be eligible for the second or next onward subscription renewals."
        />
        <Accordion
          title="How are my referrals being tracked?"
          content="We use cookies to track your referral traffic. The cookies last for 90 days after someone clicks on your link. You’ll get 90 days of restarts if they click again."
        />
        <Accordion
          title="Have more questions?"
          content="Please send a mail to “contact@groupboss.io” if you have more queries."
        />
      </ContentContainer>
    </article>
  </>
)
